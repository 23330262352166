import { Link } from "gatsby"
import React from "react"
import * as styles from "./HireIosMobile.module.scss"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
const HireIosMobile = ({ strapiData }) => {
  const dataHireIos = [
    {
      number: "10+",
      experience: "Years Experience",
    },
    {
      number: "300+",
      experience: "Project Completed",
    },
    {
      number: "97%",
      experience: "Client Satisfaction Rate",
    },
  ]
  return (
    <React.Fragment>
      <Container fluid className={styles.hireIosMobileBg}>
        <Row className="m-0">
          <Col lg={12} md={12} xs={12} sm={12}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className={styles.iosHireBtnMobile}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white1_border_hire_ios_Btn_mobile}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
            <div className={` ${styles.telant}`}>
              {dataHireIos?.map((e, i) => (
                <div className={styles.telantData} key={i}>
                  <h3 className={styles.number}>{e?.number}</h3>
                  <p className={styles.experience}>{e?.experience}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default HireIosMobile
