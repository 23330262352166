import React from "react"
import * as styles from "./IdeationMobile.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import imgmobileProcess from "../../images/IOS/iosIdelationMobile.png"
const IdeationMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileIdeationIOS}>
        <Container>
          <h2
            className={styles.titleSteps}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.bannerHeading}
            dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
          />
          <img src={imgmobileProcess} alt="" decoding="async" loading="lazy" />
          <Row className={` ${styles.cardWrapper} gap-30`}>
            {strapiData?.cards.map((e, i) => (
              <Col lg={6} md={6} xs={12} key={i}>
                <div className={styles.card}>
                  <div
                    className={styles.step}
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                  <div className={` d-flex ${styles.headingSteps}`}>
                    <h3 className={styles.cardTitleProcess}>{e?.title}</h3>
                    <p className={styles.howLong}>
                      {i === 0
                        ? "2-3 days"
                        : i === 1
                        ? "3-4 days"
                        : i === 2
                        ? "4-5 days"
                        : i === 3
                        ? "2-3 days"
                        : i === 4
                        ? "4-5 days"
                        : i === 5
                        ? "on going"
                        : ""}
                    </p>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IdeationMobile
