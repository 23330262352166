import { Link } from "gatsby"
import React from "react"
import * as styles from "./IosFeatures.module.scss"
import "./IosFeatures.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
const IosFeatures = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.IosFeaturesRevamp}>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={12} xs={12} sm={12}>
              <div className={styles.heading}>
                <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
              </div>
              <div className={styles.cost}>
                <h2
                  dangerouslySetInnerHTML={{ __html: strapiData?.subTitle2 }}
                />
                <div className={styles.iosFeatureBtn}>
                  {strapiData?.buttons[0] && (
                    <Link
                      to={strapiData?.buttons[0]?.url}
                      className={styles.btn_white1_border_hire_ios_Btn_feature}
                    >
                      {strapiData?.buttons[0]?.title}
                    </Link>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <Row className={` gap-30 ${styles.cardWrapper}`}>
                <div className={` gap-30 scrolled ${styles.scrolledData}`}>
                  {strapiData?.cards?.map((e, i) => (
                    <Col lg={12} key={i}>
                      <div className={styles.cardData}>
                        <div className={styles.cardImg}>
                          <img
                            src={e?.image1[0]?.localFile?.publicURL}
                            decoding="async"
                            loading="lazy"
                            alt={e?.title}
                          />
                        </div>
                        <div className={styles.cardContent}>
                          <h3 className={styles.cardTitle}>{e?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  ))}
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IosFeatures
