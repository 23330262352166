// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--8a372";
export var clientDots2 = "Testimonials-module--clientDots2--74dd2";
export var content = "Testimonials-module--content--642bf";
export var dots3 = "Testimonials-module--dots3--dc453";
export var hireArrow = "Testimonials-module--hireArrow--f9b33";
export var iconContainer = "Testimonials-module--iconContainer--bf8c2";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--91483";
export var imgBtn = "Testimonials-module--imgBtn--69c71";
export var person = "Testimonials-module--person--438b8";
export var personBg = "Testimonials-module--personBg--5de84";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--4f379";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--39777";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--deafa";
export var row = "Testimonials-module--row--8ab5b";
export var section = "Testimonials-module--section--7ed92";
export var staffRec = "Testimonials-module--staffRec--ed980";
export var testName = "Testimonials-module--testName--76157";
export var testimonials = "Testimonials-module--testimonials--02830";
export var testimonialsContent = "Testimonials-module--testimonialsContent--9aadb";
export var vector2 = "Testimonials-module--vector2--5c4e2";