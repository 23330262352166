import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import Testimonials2 from "../../components/healthcare/Testimonials2"
import Banner from "../../components/ios-mobile-app/Banner"
import GotProject from "../../components/ios-mobile-app/GotProject"
import Ideation from "../../components/ios-mobile-app/Ideation"
import Testimonials from "../../components/ios-mobile-app/Testimonials"
import WorkPortfolio from "../../components/common/WorkPortfolio"
import MainLayout from "../../layouts/MainLayout"
import Services from "../../components/ios-mobile-app/Services"
import HireIOS from "../../components/ios-mobile-app/HireIOS"
import Industries from "../../components/ios-mobile-app/Industries"
import IosFeatures from "../../components/ios-mobile-app/IosFeatures"
import WhyChoose from "../../components/ios-mobile-app/WhyChoose"
import EngagementModels from "../../components/common/Engagement-Model/EngagementModels"
import IosTechStacks from "../../components/ios-mobile-app/IosTechStacks"
import Emerging from "../../components/ios-mobile-app/Emerging"
import Vision from "../../components/ios-mobile-app/Vision"
import IosTechStacksMobile from "../../components/ios-mobile-app/IosTechStacksMobile"
import HireIosMobile from "../../components/ios-mobile-app/HireIosMobile"
import IosFeaturesMobile from "../../components/ios-mobile-app/IosFeaturesMobile"
import IdeationMobile from "../../components/ios-mobile-app/IdeationMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/ios_app_development_company_8ee5bfd29c.webp"
      />
    </>
  )
}
const ios = ({ data }) => {
  //console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const testimonials = data?.strapiPage?.sections[10]
  const hireIos = data?.strapiPage?.sections[12]
  const industry = data?.strapiPage?.sections[13]
  const features = data?.strapiPage?.sections[14]
  const choose = data?.strapiPage?.sections[15]
  const engagement = data?.strapiPage?.sections[16]
  const iosTech = data?.strapiPage?.sections[17]
  const emerging = data?.strapiPage?.sections[18]
  const vision = data?.strapiPage?.sections[19]
  const services = data?.strapiPage?.sections[20]
  const iosApplication = data?.strapiPage?.sections[21]
  const iosSteps = data?.strapiPage?.sections[22]
  const iosBannerRevamp = data?.strapiPage?.sections[23]
  const iosBannerQuestions = data?.strapiPage?.sections[24]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={iosBannerRevamp}
        breadCrumb={data?.strapiPage?.buttons}
      />
      <Services strapiData={services} />
      {!isMobile && !isTablet ? (
        <HireIOS strapiData={hireIos} />
      ) : (
        <HireIosMobile strapiData={hireIos} />
      )}
      <Industries strapiData={industry} />
      {!isMobile && !isTablet ? (
        <IosFeatures strapiData={features} />
      ) : (
        <IosFeaturesMobile strapiData={features} />
      )}
      {!isMobile && !isTablet ? (
        <Ideation strapiData={iosSteps} />
      ) : (
        <IdeationMobile strapiData={iosSteps} />
      )}
      <GotProject strapiData={iosApplication} pageName="iOS App Development" />
      <WhyChoose strapiData={choose} />
      <EngagementModels strapiData={engagement} iosEngagment={true} />
      {!isMobile && !isTablet ? (
        <IosTechStacks strapiData={iosTech} />
      ) : (
        <IosTechStacksMobile strapiData={iosTech} />
      )}
      <Emerging strapiData={emerging} />
      <Vision strapiData={vision} />
      <WorkPortfolio
        portfolioHead={"Our Work"}
        portfolioDesc={
          "Our exceptional iOS app development services are top notch and they speak for themselves."
        }
      />
      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={"Happy Words From Happy Clients"}
          testiSubtitle={
            "We are not just another company! We offer complete iOS application development services to our partners."
          }
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={"Happy Words From Happy Clients"}
          testiSubtitle={
            "We are not just another company! We offer complete iOS application development services to our partners."
          }
        />
      )}
      <Faqs strapiData={iosBannerQuestions} pageName="iOS App Development" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "ios" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default ios
