// extracted by mini-css-extract-plugin
export var btn_white1_border_hire_ios_Btn_mobile = "HireIosMobile-module--btn_white1_border_hire_ios_Btn_mobile--f28aa";
export var description = "HireIosMobile-module--description--8f9b1";
export var experience = "HireIosMobile-module--experience--70256";
export var heading = "HireIosMobile-module--heading--6db55";
export var hireIosMobileBg = "HireIosMobile-module--hireIosMobileBg--ccc0b";
export var hiremobileImgCol = "HireIosMobile-module--hiremobileImgCol--c6f28";
export var iosHireBtnMobile = "HireIosMobile-module--iosHireBtnMobile--ce93e";
export var number = "HireIosMobile-module--number--1e0c2";
export var telant = "HireIosMobile-module--telant--d62c6";
export var telantData = "HireIosMobile-module--telantData--43ea3";