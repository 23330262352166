// extracted by mini-css-extract-plugin
export var banerImg = "Banner-module--banerImg--8900f";
export var bannerBtn = "Banner-module--bannerBtn--043c7";
export var bannerHeading = "Banner-module--bannerHeading--63551";
export var bannerIosRevamp = "Banner-module--bannerIosRevamp--d52df";
export var bannerLeft = "Banner-module--bannerLeft--094c7";
export var bannerRight = "Banner-module--bannerRight--93c8f";
export var btn_white2_border = "Banner-module--btn_white2_border--90d38";
export var cards = "Banner-module--cards--744be";
export var head = "Banner-module--head--b9595";
export var heading = "Banner-module--heading--95448";
export var rowPad = "Banner-module--rowPad--585ac";
export var talent = "Banner-module--talent--698a9";
export var text = "Banner-module--text--8fb3d";
export var tick = "Banner-module--tick--6fd2f";