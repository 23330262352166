// extracted by mini-css-extract-plugin
export var cardBg = "IosTechStacksMobile-module--cardBg--568d9";
export var cards = "IosTechStacksMobile-module--cards--448eb";
export var cardsContent = "IosTechStacksMobile-module--cardsContent--9d593";
export var description = "IosTechStacksMobile-module--description--06785";
export var globalIndusIosRevampTech = "IosTechStacksMobile-module--globalIndusIosRevampTech--13575";
export var heading = "IosTechStacksMobile-module--heading--8d6e0";
export var iconssCard = "IosTechStacksMobile-module--iconssCard--f26d3";
export var nav = "IosTechStacksMobile-module--nav--09537";
export var navItem = "IosTechStacksMobile-module--nav-item--9ea39";
export var navbarBlock = "IosTechStacksMobile-module--navbarBlock--3d95a";
export var tabData = "IosTechStacksMobile-module--tabData--97a77";
export var tech = "IosTechStacksMobile-module--tech--faa66";
export var techIcon = "IosTechStacksMobile-module--techIcon--f5e14";
export var techImg = "IosTechStacksMobile-module--techImg--be1bc";
export var technologyIcon = "IosTechStacksMobile-module--technologyIcon--f8074";