// extracted by mini-css-extract-plugin
export var Frame = "Ideation-module--Frame--39b59";
export var Ideation = "Ideation-module--Ideation--30176";
export var arrow2 = "Ideation-module--arrow2--f2244";
export var bannerHeading = "Ideation-module--bannerHeading--09df7";
export var btn_white1_border = "Ideation-module--btn_white1_border--ba1f9";
export var cardTitleProcess = "Ideation-module--cardTitleProcess--dd1a2";
export var cards = "Ideation-module--cards--47197";
export var cards2 = "Ideation-module--cards2--a5671";
export var cardsData = "Ideation-module--cardsData--e99bb";
export var cardsDetails = "Ideation-module--cardsDetails--0bc97";
export var cir = "Ideation-module--cir--e594e";
export var circle = "Ideation-module--circle--5f957";
export var closeUp = "Ideation-module--closeUp--1d666";
export var dots = "Ideation-module--dots--80419";
export var dots2 = "Ideation-module--dots2--7a7c2";
export var heading = "Ideation-module--heading--4b30e";
export var howLong = "Ideation-module--howLong--5d37c";
export var keyBtn = "Ideation-module--keyBtn--2e139";
export var mobBg = "Ideation-module--mobBg--7adc3";
export var rec = "Ideation-module--rec--053e3";
export var step = "Ideation-module--step--a11ec";
export var titleSteps = "Ideation-module--titleSteps--15297";