// extracted by mini-css-extract-plugin
export var Ready = "Vision-module--Ready--8fd63";
export var bannerBtn = "Vision-module--bannerBtn--67778";
export var btn_white2_border_Vision_IOS = "Vision-module--btn_white2_border_Vision_IOS--98d6f";
export var card = "Vision-module--card--bae95";
export var cardWrapper = "Vision-module--cardWrapper--05dc8";
export var con = "Vision-module--con--ea6f1";
export var description = "Vision-module--description--573e4";
export var description2 = "Vision-module--description2--1d5fa";
export var heading = "Vision-module--heading--9d4dc";
export var subserviceVisionIOS = "Vision-module--subserviceVisionIOS--6ebce";