import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./IosFeaturesMobile.module.scss"

const IosFeaturesMobile = ({ strapiData }) => {
  return (
    <>
      <div className={`${styles.iosFeaturesMobileServices}`}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards.map((e, i) => (
              <Col lg={6} md={6} xs={12} key={i}>
                <div className={styles.card}>
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={styles.cost}>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.subTitle2 }} />
        <div className={styles.iosFeatureBtn}>
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className={styles.btn_white1_border_hire_ios_Btn_feature}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </div>
    </>
  )
}

export default IosFeaturesMobile
