// extracted by mini-css-extract-plugin
export var Talk = "IosTechStacks-module--Talk--1ef23";
export var background = "IosTechStacks-module--background--413c0";
export var bannerBtn = "IosTechStacks-module--bannerBtn--37302";
export var cardBg = "IosTechStacks-module--cardBg--57779";
export var cards = "IosTechStacks-module--cards--76575";
export var cardsContent = "IosTechStacks-module--cardsContent--b13dc";
export var description = "IosTechStacks-module--description--d000a";
export var divider = "IosTechStacks-module--divider--3bf83";
export var globalIndusIOSStacks = "IosTechStacks-module--globalIndusIOSStacks--35f9f";
export var heading = "IosTechStacks-module--heading--e273f";
export var hireCard = "IosTechStacks-module--hireCard--73897";
export var iconssCard = "IosTechStacks-module--iconssCard--85e8d";
export var nav = "IosTechStacks-module--nav--0ce96";
export var navItem = "IosTechStacks-module--nav-item--902cc";
export var navbarBlock = "IosTechStacks-module--navbarBlock--feda1";
export var solutionHeadig = "IosTechStacks-module--solutionHeadig--f3b34";
export var tabData = "IosTechStacks-module--tabData--a5dc1";
export var tabHeading = "IosTechStacks-module--tabHeading--01a28";
export var tabSubHeeadig = "IosTechStacks-module--tabSubHeeadig--77148";
export var tech = "IosTechStacks-module--tech--61673";
export var techIcon = "IosTechStacks-module--techIcon--239be";
export var techImg = "IosTechStacks-module--techImg--cffb2";
export var technologyIcon = "IosTechStacks-module--technologyIcon--36c1a";