// extracted by mini-css-extract-plugin
export var IosFeaturesRevamp = "IosFeatures-module--IosFeaturesRevamp--4632e";
export var btn_white1_border_hire_ios_Btn_feature = "IosFeatures-module--btn_white1_border_hire_ios_Btn_feature--a8f29";
export var cardContent = "IosFeatures-module--cardContent--78843";
export var cardData = "IosFeatures-module--cardData--d19fa";
export var cardImg = "IosFeatures-module--cardImg--349f8";
export var cardTitle = "IosFeatures-module--cardTitle--a5745";
export var cardWrapper = "IosFeatures-module--cardWrapper--cecc3";
export var cost = "IosFeatures-module--cost--e4210";
export var description = "IosFeatures-module--description--d71c4";
export var heading = "IosFeatures-module--heading--c509d";
export var iosFeatureBtn = "IosFeatures-module--iosFeatureBtn--4dc90";
export var scrolledData = "IosFeatures-module--scrolledData--c8984";