import React from "react"
import Container from "react-bootstrap/Container"
import Dot from "../../images/IOS/closeDot1.png"
import rec from "../../images/IOS/rec.png"
import circle from "../../images/IOS/circle.png"
import Frame from "../../images/IOS/Frame.png"
import cir from "../../images/IOS/cir.png"
import arrow2 from "../../images/IOS/arrow2.png"
import ideation from "../../images/IOS/idealotion.png"
import * as styles from "./Ideation.module.scss"

const Ideation = ({ strapiData }) => {
  return (
    <div className={styles.Ideation}>
      <img
        src={Dot}
        className={styles.dots}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={rec}
        className={styles.rec}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={circle}
        className={styles.circle}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <Container>
        <h2
          className={styles.titleSteps}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.bannerHeading}
          dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
        />
        <div className={styles.cardsData}>
          <div className={styles.cardsDetails}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i % 2 === 0 && (
                    <div className={styles.cards} key={i}>
                      <div
                        className={styles.step}
                        dangerouslySetInnerHTML={{
                          __html: e?.description?.description,
                        }}
                      />
                      <div className={` d-flex`}>
                        <h3 className={styles.cardTitleProcess}>{e?.title}</h3>
                        <p className={styles.howLong}>
                          {i === 0
                            ? "2-3 days"
                            : i === 2
                            ? "4-5 days"
                            : i === 4
                            ? "4-5 days"
                            : ""}
                        </p>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
          <div className={styles.closeUp}>
            <img
              src={ideation}
              decoding="async"
              loading="lazy"
              alt="Our iOS App Development Process"
            />
          </div>
          <div className={styles.cardsDetails}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i % 2 !== 0 && (
                    <div className={styles.cards2} key={i}>
                      <div
                        className={styles.step}
                        dangerouslySetInnerHTML={{
                          __html: e?.description?.description,
                        }}
                      />
                      <div className={` d-flex `}>
                        <h3 className={styles.cardTitleProcess}>{e?.title}</h3>
                        <p className={styles.howLong}>
                          {i === 1
                            ? "3-4 days"
                            : i === 3
                            ? "2-3 days"
                            : i === 5
                            ? "on going"
                            : ""}
                        </p>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
        <img
          src={Dot}
          className={styles.dots2}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
        <img
          src={cir}
          className={styles.cir}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
        <img
          src={arrow2}
          className={styles.arrow2}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
      </Container>
      <img
        src={Frame}
        className={styles.Frame}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
    </div>
  )
}

export default Ideation
