// extracted by mini-css-extract-plugin
export var Group = "IosFeaturesMobile-module--Group--5374f";
export var arrowImg = "IosFeaturesMobile-module--arrowImg--90279";
export var btn_white1_border_hire_ios_Btn_feature = "IosFeaturesMobile-module--btn_white1_border_hire_ios_Btn_feature--36a1f";
export var card = "IosFeaturesMobile-module--card--37ac2";
export var card3 = "IosFeaturesMobile-module--card3--6ff27";
export var cardWrapper = "IosFeaturesMobile-module--cardWrapper--878c4";
export var choseImg = "IosFeaturesMobile-module--choseImg--e99d5";
export var cir = "IosFeaturesMobile-module--cir--7b8a8";
export var cost = "IosFeaturesMobile-module--cost--4da9d";
export var description = "IosFeaturesMobile-module--description--1e5f2";
export var dots1 = "IosFeaturesMobile-module--dots1--ad012";
export var dots2 = "IosFeaturesMobile-module--dots2--929e1";
export var dots3 = "IosFeaturesMobile-module--dots3--c3200";
export var dots4 = "IosFeaturesMobile-module--dots4--2794b";
export var for1 = "IosFeaturesMobile-module--for1--2fa72";
export var heading = "IosFeaturesMobile-module--heading--6b8eb";
export var hireArrow = "IosFeaturesMobile-module--hireArrow--fbd3d";
export var iosFeatureBtn = "IosFeaturesMobile-module--iosFeatureBtn--efc5f";
export var iosFeaturesMobileServices = "IosFeaturesMobile-module--iosFeaturesMobileServices--069b4";
export var keyBtn = "IosFeaturesMobile-module--keyBtn--9ef18";
export var staffRec = "IosFeaturesMobile-module--staffRec--a7899";
export var teamBtn = "IosFeaturesMobile-module--teamBtn--d4a66";
export var well = "IosFeaturesMobile-module--well--34e33";
export var wellRec = "IosFeaturesMobile-module--wellRec--fe22f";