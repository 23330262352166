// extracted by mini-css-extract-plugin
export var ServicesRefineMianIosWeb = "Services-module--ServicesRefineMianIosWeb--97a3d";
export var card = "Services-module--card--a4601";
export var cardWrapper = "Services-module--cardWrapper--08543";
export var description = "Services-module--description--745aa";
export var heading = "Services-module--heading--7bd8a";
export var loader = "Services-module--loader--574c7";
export var lodaMoreBtn = "Services-module--lodaMoreBtn--11ae9";
export var lodalessBtn = "Services-module--lodalessBtn--0f37b";
export var showlessdiv = "Services-module--showlessdiv--234e6";
export var spin = "Services-module--spin--e1d0c";
export var trailBg = "Services-module--trailBg--77ef0";