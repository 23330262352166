import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./IosTechStacksMobile.scss"
import * as styles from "./IosTechStacksMobile.module.scss"

const IosTechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Programming",
      tech: [
        {
          name: "Objective-C",
          icon: "https://invozone-backend.s3.amazonaws.com/objective_c_97ed4fff16.svg",
        },
        {
          name: "Swift/SwiftUI",
          icon: "https://invozone-backend.s3.amazonaws.com/swift_bd4f360fb3.svg",
        },
        {
          name: "Dart",
          icon: "https://invozone-backend.s3.amazonaws.com/dart_technology_965da8f69d.svg",
        },
        {
          name: "Java Script",
          icon: "https://invozone-backend.s3.amazonaws.com/javascript_3eb254d0c1.svg",
        },
      ],
    },
    {
      title: "Frameworks",
      tech: [
        {
          name: "React Native",
          icon: "https://invozone-backend.s3.amazonaws.com/react_1e4285ce5c.svg",
        },
        {
          name: "Flutter",
          icon: "https://invozone-backend.s3.amazonaws.com/flutter_c0ad0503c7.svg",
        },
        {
          name: "Ionic",
          icon: "https://invozone-backend.s3.amazonaws.com/ionic_b491edfd18.svg",
        },
        {
          name: "Apple UI Kit",
          icon: "https://invozone-backend.s3.amazonaws.com/ionic_b491edfd18.svg",
        },
        {
          name: "ARKit",
          icon: "https://invozone-backend.s3.amazonaws.com/arkit_3b67a0806a.svg",
        },
        {
          name: "Core ML",
          icon: "https://invozone-backend.s3.amazonaws.com/core_ml_0146f8c445.svg",
        },
        {
          name: "Core Location",
          icon: "https://invozone-backend.s3.amazonaws.com/core_location_6514fa5b1c.svg",
        },
        {
          name: "Watch Connectivity",
          icon: "https://invozone-backend.s3.amazonaws.com/watch_os_6495335368.svg",
        },
        {
          name: "Core Data",
          icon: "https://invozone-backend.s3.amazonaws.com/core_data_79edc5c60e.svg",
        },
        {
          name: "WebKit",
          icon: "https://invozone-backend.s3.amazonaws.com/webkit_94d6296654.svg",
        },
        {
          name: "CloudKit",
          icon: "https://invozone-backend.s3.amazonaws.com/cloudkit_f928d25639.svg",
        },
        {
          name: "Open GL ES",
          icon: "https://invozone-backend.s3.amazonaws.com/open_gl_es_ba3b5d1a4a.svg",
        },
        {
          name: "Cocoa Touch",
          icon: "https://invozone-backend.s3.amazonaws.com/cocoa_touch_1e1d99ded6.svg",
        },
        {
          name: "ARKit",
          icon: "https://invozone-backend.s3.amazonaws.com/arkit_3b67a0806a.svg",
        },
        {
          name: "Core Audio",
          icon: "https://invozone-backend.s3.amazonaws.com/core_audio_8ce65ae7ad.svg",
        },
        {
          name: "Core Animation",
          icon: "https://invozone-backend.s3.amazonaws.com/core_animation_logo_c65806c872.svg",
        },
        {
          name: "Titanium",
          icon: "https://invozone-backend.s3.amazonaws.com/titanium_b1145bc4bb.svg",
        },
        {
          name: "Unity 3D",
          icon: "https://invozone-backend.s3.amazonaws.com/unity_3d_83ec8dd34f.svg",
        },
        {
          name: "Apache Cordova",
          icon: "https://invozone-backend.s3.amazonaws.com/apache_cordova_1c4f74c85d.svg",
        },
      ],
    },
    {
      title: "ORM’s & Databases",
      tech: [
        {
          name: "Firebase",
          icon: "https://invozone-backend.s3.amazonaws.com/firebase_6cd830fadd.svg",
        },
        {
          name: "SQLite",
          icon: "https://invozone-backend.s3.amazonaws.com/sqlite_38e23119be.svg",
        },
        {
          name: "Core Data",
          icon: "https://invozone-backend.s3.amazonaws.com/core_data_b552024c63.svg",
        },
        {
          name: "Rest Kit",
          icon: "https://invozone-backend.s3.amazonaws.com/rest_kit_c107c8bb2b.svg",
        },
        {
          name: "Realm",
          icon: "https://invozone-backend.s3.amazonaws.com/realm_f34a279ac1.svg",
        },
        {
          name: "PostgreSQL",
          icon: "https://invozone-backend.s3.amazonaws.com/postgresql_2356a618ea.svg",
        },
        {
          name: "FMDB",
          icon: "https://invozone-backend.s3.amazonaws.com/fmdb_5e3bcf9b3c.svg",
        },
      ],
    },
    {
      title: "Solutions & Tools",
      tech: [
        {
          name: "Xcode",
          icon: "https://invozone-backend.s3.amazonaws.com/xcode_a487aa6d33.svg",
        },
        {
          name: "TestFlight",
          icon: "https://invozone-backend.s3.amazonaws.com/testflight_66b4138ef8.svg",
        },
        {
          name: "Universal Analytics",
          icon: "https://invozone-backend.s3.amazonaws.com/universa_analytics_4f0757d9b6.svg",
        },
        {
          name: "Mockingbird",
          icon: "https://invozone-backend.s3.amazonaws.com/mockingbird_9574308221.svg",
        },
        {
          name: "ASIHTTPRequest",
          icon: "https://invozone-backend.s3.amazonaws.com/ashittp_request_b80f82fdfa.svg",
        },
        {
          name: "ShareKit",
          icon: "https://invozone-backend.s3.amazonaws.com/sharekit_d8be0f1052.svg",
        },
        {
          name: "Urban Airship",
          icon: "https://invozone-backend.s3.amazonaws.com/urban_airship_511a7bcf8e.svg",
        },
        {
          name: "​​Twilio",
          icon: "https://invozone-backend.s3.amazonaws.com/twilio_6de5194a51.svg",
        },
        {
          name: "HockeyApp",
          icon: "https://invozone-backend.s3.amazonaws.com/hockeyapp_a0ad01771b.svg",
        },
        {
          name: "Wikitude",
          icon: "https://invozone-backend.s3.amazonaws.com/wikitude_ba474d56a2.svg",
        },
        {
          name: "VS Code",
          icon: "https://invozone-backend.s3.amazonaws.com/vs_code_7504aa666d.svg",
        },
      ],
    },
    {
      title: "API's",
      tech: [
        {
          name: "Rest APIs",
          icon: "https://invozone-backend.s3.amazonaws.com/rest_apis_ec5e76b0a3.svg",
        },
        {
          name: "gRPC",
          icon: "https://invozone-backend.s3.amazonaws.com/grpc_3e565c754e.svg",
        },
        {
          name: "Web Sockets",
          icon: "https://invozone-backend.s3.amazonaws.com/sockets_2_34f860a115.svg",
        },
        {
          name: "GraphQl",
          icon: "https://invozone-backend.s3.amazonaws.com/graph_Ql_2_51bcd173ff.svg",
        },
        {
          name: "Apache",
          icon: "https://invozone-backend.s3.amazonaws.com/apache_109a896795.svg",
        },
        {
          name: "Kafka",
          icon: "https://invozone-backend.s3.amazonaws.com/kafka_f99f931d9b.svg",
        },
        {
          name: "RabbitMQ",
          icon: "https://invozone-backend.s3.amazonaws.com/rabbit_3e6d3a9f46.svg",
        },
      ],
    },
    {
      title: "Deployment",
      tech: [
        {
          name: "AWS",
          icon: "https://invozone-backend.s3.amazonaws.com/aws_b29d4b8cfa.svg",
        },
        {
          name: "Google Cloud",
          icon: "https://invozone-backend.s3.amazonaws.com/google_cloud_2e0a58b2fd.svg",
        },
        {
          name: "Microsoft Azure",
          icon: "https://invozone-backend.s3.amazonaws.com/azure_0b43c63ff1.svg",
        },
        {
          name: "DigitalOcean",
          icon: "https://invozone-backend.s3.amazonaws.com/digital_ocean_846dfa5275.svg",
        },
        {
          name: "Vercel",
          icon: "https://invozone-backend.s3.amazonaws.com/vercel_ce4af9ce51.svg",
        },
        {
          name: "ImgIX",
          icon: "https://invozone-backend.s3.amazonaws.com/imgix_c67f0818b0.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="TechIosRevampMobile">
      <div className={styles.globalIndusIosRevampTech}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion
            defaultActiveKey={`${data[0]?.title}`}
            className="iosAccordian"
          >
            {data &&
              data?.map((e, i) => (
                <Card key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`TechIosRevampMobileAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion-tech-ios"
                        : (i === 0 || i === 2 || i === 4) &&
                          e?.title !== activeAccordion
                        ? "first-accordion-tech-ios"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`TechIosRevampMobileBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default IosTechStacksMobile
